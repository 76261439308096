<template>
  <div>
    <a
      class="has-text-weight-medium has-text-right is-block"
      @click="openModal"
      >{{ $t("af:profile.modal.password.button") }}</a
    >

    <b-modal v-model="isModalOpen" :can-cancel="false" :width="500">
      <div class="af-modal-card">
        <p class="has-text-weight-bold is-size-4 mb-6">
          {{ $t("af:profile.modal.password.title") }}
        </p>

        <form @submit.prevent="save">
          <validation-observer ref="observer">
            <BInputWithValidation
              type="password"
              autocomplete="new-password"
              rules="required"
              :label="$t('af:profile.modal.password.input.old_password.label')"
              label-position="on-border"
              v-model="oldPassword"
            />

            <b-field
              :label="$t('af:forgotten_password.input.new_password.label')"
              rules="required"
              label-position="on-border"
            >
              <PasswordCheckerInput
                v-model="newPassword"
                @validated="passwordValid = $event"
              />
            </b-field>

            <b-field grouped class="mt-4">
              <b-button
                type="is-blue"
                :value="$t('af:profile.modal.password.button.save')"
                tag="input"
                @click="save"
              ></b-button>

              <b-button type="is-text" class="ml-4" @click="cancelModal">{{
                $t("af:profile.modal.password.button.cancel")
              }}</b-button>
            </b-field>
          </validation-observer>
        </form>

        <div class="close-btn cursor-pointer" @click="cancelModal">
          <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "UserEditNameModal",
  components: {
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
    PasswordCheckerInput: () => import("@/components/PasswordCheckerInput.vue"),
  },
  data() {
    return {
      isModalOpen: false,
      oldPassword: "",
      newPassword: "",
      passwordValid: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    cancelModal() {
      this.isModalOpen = false;
      this.oldPassword = "";
      this.newPassword = "";
    },
    async save() {
      const valid = await this.$refs.observer.validate();

      if (!valid || !this.passwordValid) return;

      try {
        await this.$store.dispatch("profile/passwordChange", {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });
        this.$buefy.toast.open({
          message: i18n.t("af:profile.modal.password.message.successful_save"),
          type: "is-success",
        });
        this.cancelModal();
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("af:profile.modal.password.message.failed_save"),
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped></style>
